import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { cSessionService } from '../services/session.service';

const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const SessionService = inject(cSessionService);
  const router = inject(Router);
  const oUser=SessionService.SessionService_GetUserDetails()
   if(oUser.role == "admin" || oUser.role == "*") 
    {
      return true;
    } 
    else
    {
      router.navigate(['/datasource']);
      return false;
    }
};
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}
