import { Component, OnInit,Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translate(0,-100%)'}),
        animate('300ms ease-in', style({transform: 'translate(0%,0%)'}))
      ]),
      transition(':leave', [
        animate('1s ease-in', style({transform: 'translate(0%,0%)'}))
      ])
    ])
  ]
})
export class AlertMessagesComponent implements OnInit {

  constructor() { }
  @Input() resMessage;
  ngOnInit(): void {
   setTimeout(() => {
   }, 3000);
    
  }

}
