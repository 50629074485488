import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { cSessionService } from '../services/session.service';

const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const SessionService = inject(cSessionService);
  const router = inject(Router);
      const lsConfig = SessionService.SessionService_GetApplicationSideBarConfigurationFile();
      const urlWithQuery = state.url;
      const baseUrl = urlWithQuery.split('?')[0];
      if (Object.keys(lsConfig).length > 0) {
        const isMenuValid = lsConfig.menus.some((element) => {
          return element.routerLink === baseUrl || element.routerLink === '/usermanager';
        });

        if (isMenuValid) {
          return true;
        } else {
          router.navigate([lsConfig.menus[0].routerLink]);
          return false;
        }
      }
};
export const canActivateNavItem: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}
