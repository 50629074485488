import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { cSessionService } from '../services/session.service';

const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const SessionService = inject(cSessionService);
  const router = inject(Router);
   let accessToken = SessionService.SessionService_GetAccessToken();
   if(Object.keys(accessToken).length > 0) 
    {
      return true;
    } 
    else
    {
      router.navigate(['auth/login']);
      return false;
    }
};
export const canActivateTeam: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}
