import { Injectable } from '@angular/core';
import { HttpRequest,HttpHandler,HttpEvent,HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { cSessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class cHeadersInterceptor implements HttpInterceptor {
  constructor(private oSessionService:cSessionService){}
  oRequestWithHeaders  : any;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> { 
    let oHeaders = 
    {
      'Accept':'*/*',
      'x-app-name':'main',
      'x-org-name': environment.OrgName,
      'content-type':'application/json',
      'Authorization':'Bearer ' + this.oSessionService.SessionService_GetAccessToken(),
    }
    if (request.url.includes('/uploadUsers')) delete oHeaders['content-type'];
    if((request.url.includes('ajaxLogin')) || 
       (request.url.includes('_systemInfo')) ||
       (request.url.includes('/forgotPassword')) || 
       (request.url.includes('/_preauthsvc/user/resetPassword')) ||
       (request.url.includes('/ds/preauth_function/shared/GetLogo')) || 
       (request.url.includes('/ds/preauth_function/shared/GetLoginConfig')) || 
       (request.url.includes('/_preauthsvc/user/validateTenant')) ||
       (request.url.includes('/_oauth2/url')) ||
       (request.url.includes('_rt/appVersion'))) delete oHeaders['Authorization'];
      //  if(request.url.includes('/upload')) delete oHeaders['content-type'];
       if(request.url.includes('/saveLogo') || request.url.includes('/importMetadata')) delete oHeaders['content-type'];
    if((request.url.includes('/_service/public/signedAction?secret=')) || 
       (request.url.includes('retieveSignedUrlPublic')) || (request.url.includes('_service/public/saveFileObjectPublic')))
       {
         this.oRequestWithHeaders  = request.clone({ setHeaders: {
          'Accept':'*/*',
          'x-app-name':'filesharing',
          'x-org-name': environment.OrgName,
          'content-type':'application/json',
         } });
       }
       else
       {
         this.oRequestWithHeaders  = request.clone({ setHeaders: oHeaders });
       }
        return next.handle(this.oRequestWithHeaders)
    .pipe(retry(0), catchError((error: HttpErrorResponse)=> {
      let errorMessage: any;
      if(error.error instanceof ErrorEvent){
        //client side error
        errorMessage = `Error: ${error.error.message}`;
      }
      else
      {
        //server side error
        // errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        errorMessage = error.error.message
      }
      return throwError(() => new Error(errorMessage))
    }))
  }
}
